import React from "react"
import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import { devLog, renderCarouselItem } from "../utils"
import AtsGrid from "../components/AtsGrid"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"

const AllCategoryPage = ({ data }) => {
  const { t } = useTranslation()
  const categoryList = data.ats.categories

  devLog({ categoryList })

  return (
    <PageRoot title={t("label:categories")} showTitle>
      <div className="category-all-wrapper">
        <ContainerWithPadding size={"large"}>
          <AtsGrid dataSource={categoryList} renderItem={renderCarouselItem} />
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query allCategories {
    ats {
      categories(order_by: { name: asc }, where: { _not: { parent: {} } }) {
        ...PreviewCardCategory
      }
    }
  }
`

export default AllCategoryPage
